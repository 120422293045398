import { render, staticRenderFns } from "./EventProgramTable.vue?vue&type=template&id=14e503fa&scoped=true"
import script from "./EventProgramTable.vue?vue&type=script&lang=js"
export * from "./EventProgramTable.vue?vue&type=script&lang=js"
import style0 from "./EventProgramTable.vue?vue&type=style&index=0&id=14e503fa&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "14e503fa",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {EventProgramTableItem: require('/builds/gladwell/gladwell-academy-frontend/components/EventProgramTableItem.vue').default})
