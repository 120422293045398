
import { parse } from 'date-fns';
import GwButton from '~/patterns/atoms/button/button.vue';
import GwSection from '~/patterns/organisms/section/section.vue';
import EventProgramTableItem from '~/components/EventProgramTableItem.vue';

export default {
    components: {
        GwButton,
        GwSection,
        EventProgramTableItem
    },

    props: {
        title: {
            type: String,
            required: true
        },
        programItems: {
            type: Array,
            required: true,
            default: () => []
        }
    },

    data() {
        return {
            selectedDate: null
        };
    },

    computed: {
        activeDate() {
            return this.selectedDate || this.dates[0];
        },

        programItemsByDate() {
            // Group the programme by date and sort them by time
            return this.programItems.reduce((items, item) => {
                const dateString = item.date.split('T')[0];
                if (!items[dateString]) {
                    items[dateString] = [];
                }
                items[dateString].push(item);
                items[dateString].sort((a, b) => a.time.localeCompare(b.time));
                return items;
            }, {});
        },

        dates() {
            // Return the dates from programItemsByDate
            return Object.keys(this.programItemsByDate);
        }
    },

    methods: {
        parse
    }
};
