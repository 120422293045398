
import CraftLink from '~/components/CraftLink.vue';

export default {
    components: {
        CraftLink
    },

    props: {
        speaker: {
            type: Object,
            required: true
        }
    }
};
