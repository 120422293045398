
import EventProgramTableItemSpeaker from '~/components/EventProgramTableItemSpeaker.vue';
import CraftLink from '~/components/CraftLink.vue';

export default {
    components: {
        CraftLink,
        EventProgramTableItemSpeaker
    },

    props: {
        item: {
            type: Object,
            required: true
        },
        showTime: {
            type: Boolean,
            default: true
        },
    }
};
