import { render, staticRenderFns } from "./EventProgramTableItem.vue?vue&type=template&id=4f5b7277&scoped=true"
import script from "./EventProgramTableItem.vue?vue&type=script&lang=js"
export * from "./EventProgramTableItem.vue?vue&type=script&lang=js"
import style0 from "./EventProgramTableItem.vue?vue&type=style&index=0&id=4f5b7277&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4f5b7277",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {CraftLink: require('/builds/gladwell/gladwell-academy-frontend/components/CraftLink.vue').default,EventProgramTableItemSpeaker: require('/builds/gladwell/gladwell-academy-frontend/components/EventProgramTableItemSpeaker.vue').default})
